import { Link } from 'gatsby';
import React from 'react';
import Cookies from 'universal-cookie';
import Modal from '../Modal';
import { useAppDispatch, useAppSelector } from '../../hooks';
import * as styles from './index.module.scss';
import {
    setShowPersonalizedAds,
    setShowCookieModal,
} from '../../store/reducers/consentSlice';

const isBrowser = typeof window !== 'undefined';

type CookieConsentType = {
    showPersonalizedAds: boolean;
    acceptedTs?: number;
};

const Layout = ({ children, location }) => {
    const dispatch = useAppDispatch();

    const [state, setState] = React.useState<CookieConsentType>({
        showPersonalizedAds: true,
    });

    const showCookieModal = useAppSelector(
        (state) => state.consent.showCookieModal
    );

    const ignoreConsentModal =
        location?.pathname === '/cookie-policy' ||
        location?.pathname === '/terms-of-service' ||
        location?.pathname === '/privacy-policy';
    React.useEffect(() => {
        const cookies = new Cookies();
        let cookieRawValue = cookies.get('skissan_consent');
        if (cookieRawValue === undefined || cookieRawValue.acceptedTs == null) {
            if (!ignoreConsentModal) {
                dispatch(setShowCookieModal());
            }
            return;
        }

        dispatch(
            setShowPersonalizedAds({
                showPersonalizedAds: cookieRawValue.showPersonalizedAds,
                acceptedTs: cookieRawValue.acceptedTs,
                hasAccepted: true,
                showCookieModal: false,
            })
        );

        setState({
            showPersonalizedAds: cookieRawValue.showPersonalizedAds,
            acceptedTs: cookieRawValue.acceptedTs,
        });
    }, [ignoreConsentModal]);

    const onSaveCookiePreference = () => {
        const cookies = new Cookies();
        cookies.set(
            'skissan_consent',
            JSON.stringify({
                showPersonalizedAds: state.showPersonalizedAds,
                acceptedTs: new Date().getTime(),
            }),
            {
                sameSite: 'strict',
                path: '/',
                expires: new Date(8888, 1, 1, 0, 0, 1),
            }
        );
        setState({
            ...state,
            acceptedTs: new Date().getTime(),
        });

        dispatch(
            setShowPersonalizedAds({
                showPersonalizedAds: state.showPersonalizedAds,
                acceptedTs: new Date().getTime(),
                hasAccepted: true,
                showCookieModal: false,
            })
        );
    };

    return (
        <>
            <main>
                {children}

                <div className={styles.modals}>
                    <Modal
                        isVisible={showCookieModal}
                        title='Allow the use of cookies from Skissan on this browser?'
                        actions={
                            <button
                                className="button"
                                type='button'
                                onClick={onSaveCookiePreference}
                            >
                                Save and exit
                            </button>
                        }
                    >
                        <p>
                            We use cookies to help provide, personalize and
                            improve the experience on Skissan. We also use them
                            for ads and analytics, and to provide a safer
                            experience. You can learn more about how we use
                            cookies in our{' '}
                            <a href='/cookie-policy' target='_blank'>
                                Cookie Policy.
                            </a>
                        </p>
                        <section className={styles.cookieSection}>
                            <h4>Essential Cookies</h4>
                            <div>
                                <p>
                                    These cookies are necessary to play Skissan
                                    and for the website to work as intended.
                                </p>

                                <label className='switch'>
                                    <input
                                        readOnly
                                        disabled
                                        type='checkbox'
                                        checked
                                    />
                                    <span className='slider round'></span>
                                </label>
                            </div>
                        </section>
                        <section className={styles.cookieSection}>
                            <h4>Personalized ads</h4>
                            <div>
                                <p>
                                    These cookies are set by our advertisement
                                    partners to serve you personalized ads.
                                </p>
                                <label className='switch'>
                                    <input
                                        type='checkbox'
                                        checked={state.showPersonalizedAds}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                showPersonalizedAds:
                                                    e.target.checked,
                                            })
                                        }
                                    />
                                    <span className='slider round'></span>
                                </label>
                            </div>
                        </section>
                    </Modal>
                    {/* <Modal>
                        <UserCustomize />
                    </Modal> */}
                </div>
            </main>

            <footer className={styles.footer}>
                <section>
                    <Link to='/about/'>About</Link>
                    {` `}
                    <Link to='/privacy-policy/'>Privacy Policy</Link>
                    {` `}
                    <Link to='/terms-of-service/'>Terms of Service</Link>
                    {` `}
                    <Link to='/cookie-policy/'>Cookie Policy</Link>
                </section>
                <p className={styles.copyright}>
                    © {new Date().getUTCFullYear()}{' '}
                    <a
                        title='Visit the Slidesome website'
                        href='https://slidesome.com'
                        target='_blank'
                    >
                        Slidesome
                    </a>{' '}
                </p>
                <p className={styles.version}>
                    <i>version 0.90</i>
                </p>
            </footer>
        </>
    );
};

export default Layout;
