import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ConsentState {
    hasAccepted: boolean;
    showPersonalizedAds: boolean;
    acceptedTs: number;
    showCookieModal: boolean;
    ignoreModal?: boolean;
}

const initialState: ConsentState = {
    showPersonalizedAds: true,
    acceptedTs: -1,
    hasAccepted: false,
    showCookieModal: false,
    ignoreModal: false,
};

export const consentSlice = createSlice({
    name: 'consent',
    initialState,
    reducers: {
        setShowPersonalizedAds: (state, action: PayloadAction<ConsentState>) => {
            state.showPersonalizedAds = action.payload.showPersonalizedAds;
            state.acceptedTs = action.payload.acceptedTs;
            state.hasAccepted = action.payload.hasAccepted;
            state.showCookieModal = false;
        },

        setShowCookieModal: (state) => {
            state.showCookieModal = true;
            state.acceptedTs = -1;
            state.hasAccepted = false;
        },

        setIgnoreModal: (state, action: PayloadAction<boolean>) => {
            state.ignoreModal = action.payload;
        }
    },
});

// Action creators are generated for each case reducer function
export const { setShowPersonalizedAds, setShowCookieModal, setIgnoreModal } = consentSlice.actions;

export default consentSlice.reducer;
