const avatarHelper = {
    typeIndex: {
        top: 0,
        hairColor: 1,
        hatColor: 2,
        accessories: 3,
        eye: 4,
        eyeBrow: 5,
        mouth: 6,
        facialHair: 7,
        facialHairColor: 8,
        clothe: 9,
        clotheColor: 10,
        skinColor: 11,
        graphic: 12,
    },
    topType: [
        'NoHair',
        // 'Eyepatch',
        'Hat',
        'Hijab',
        'Turban',
        'WinterHat1',
        'WinterHat2',
        'WinterHat3',
        'WinterHat4',
        'LongHairBigHair',
        'LongHairBob',
        'LongHairBun',
        'LongHairCurly',
        'LongHairCurvy',
        'LongHairDreads',
        'LongHairFrida',
        'LongHairFro',
        'LongHairFroBand',
        'LongHairNotTooLong',
        'LongHairShavedSides',
        'LongHairMiaWallace',
        'LongHairStraight',
        'LongHairStraight2',
        'LongHairStraightStrand',
        'ShortHairDreads01',
        'ShortHairDreads02',
        'ShortHairFrizzle',
        'ShortHairShaggyMullet',
        'ShortHairShortCurly',
        'ShortHairShortFlat',
        'ShortHairShortRound',
        'ShortHairShortWaved',
        'ShortHairSides',
        'ShortHairTheCaesar',
        'ShortHairTheCaesarSidePart',
    ],
    accessoriesType: [
        'Blank',
        'Kurt',
        'Prescription01',
        'Prescription02',
        'Round',
        'Sunglasses',
        'Wayfarers',
    ],
    hatColor: [
        'Black',
        'Blue01',
        'Blue02',
        'Blue03',
        'Gray01',
        'Gray02',
        'Heather',
        'PastelBlue',
        'PastelGreen',
        'PastelOrange',
        'PastelRed',
        'PastelYellow',
        'Pink',
        'Red',
        'White',
    ],
    hairColor: [
        'Auburn',
        'Black',
        'Blonde',
        'BlondeGolden',
        'Brown',
        'BrownDark',
        'PastelPink',
        'Platinum',
        'Red',
        'SilverGray',
    ],
    facialHairType: [
        'Blank',
        'BeardMedium',
        'BeardLight',
        'BeardMajestic',
        'MoustacheFancy',
        'MoustacheMagnum',
    ],
    facialHairColor: [
        'Auburn',
        'Black',
        'Blonde',
        'BlondeGolden',
        'Brown',
        'BrownDark',
        'Platinum',
        'Red',
    ],
    clotheType: [
        'BlazerShirt',
        'BlazerSweater',
        'CollarSweater',
        'GraphicShirt',
        'Hoodie',
        'Overall',
        'ShirtCrewNeck',
        'ShirtScoopNeck',
        'ShirtVNeck',
    ],
    clotheColor: [
        'Black',
        'Blue01',
        'Blue02',
        'Blue03',
        'Gray01',
        'Gray02',
        'Heather',
        'PastelBlue',
        'PastelGreen',
        'PastelOrange',
        'PastelRed',
        'PastelYellow',
        'Pink',
        'Red',
        'White',
    ],
    graphicType: [
        'Bat',
        // 'Cumbia',
        'Deer',
        'Diamond',
        'Hola',
        'Pizza',
        'Resist',
        // 'Selena',
        'Bear',
        'SkullOutline',
        'Skull',
    ],
    eyeType: [
        'Close',
        'Cry',
        'Default',
        'Dizzy',
        'EyeRoll',
        'Happy',
        'Hearts',
        'Side',
        'Squint',
        'Surprised',
        'Wink',
        'WinkWacky',
    ],
    eyebrowType: [
        'Angry',
        'AngryNatural',
        'Default',
        'DefaultNatural',
        'FlatNatural',
        'RaisedExcited',
        'RaisedExcitedNatural',
        'SadConcerned',
        'SadConcernedNatural',
        'UnibrowNatural',
        'UpDown',
        'UpDownNatural',
    ],
    mouthType: [
        'Concerned',
        'Default',
        'Disbelief',
        'Eating',
        'Grimace',
        'Sad',
        'ScreamOpen',
        'Serious',
        'Smile',
        'Tongue',
        'Twinkle',
        'Vomit',
    ],
    skinColor: [
        'Tanned',
        'Yellow',
        'Pale',
        'Light',
        'Brown',
        'DarkBrown',
        'Black',
    ],
    colors: {
        Auburn: '#A55728',
        Black: '#2C1B18',
    }
};

export default avatarHelper;

export const getRandomAvatar = () => [
    Math.floor(Math.random() * avatarHelper.topType.length),
    Math.floor(Math.random() * avatarHelper.hairColor.length),
    Math.floor(Math.random() * avatarHelper.hatColor.length),
    Math.floor(Math.random() * avatarHelper.accessoriesType.length),
    Math.floor(Math.random() * avatarHelper.eyeType.length),
    Math.floor(Math.random() * avatarHelper.eyebrowType.length),
    Math.floor(Math.random() * avatarHelper.mouthType.length),
    Math.floor(Math.random() * avatarHelper.facialHairType.length),
    Math.floor(Math.random() * avatarHelper.facialHairColor.length),
    Math.floor(Math.random() * avatarHelper.clotheType.length),
    Math.floor(Math.random() * avatarHelper.clotheColor.length),
    Math.floor(Math.random() * avatarHelper.skinColor.length),
    Math.floor(Math.random() * avatarHelper.graphicType.length),
];
