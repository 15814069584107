import React from 'react';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import Layout from './components/Layout';
import { store } from './store';

const wrapper = ({ element, props }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts

    return (
        <Provider store={store}>
            <Helmet>
                <meta
                    name='description'
                    content='Play online drawing games with your friends.'
                />
                <meta
                    name='keywords'
                    content='online pictionary, pictionary game, drawing games, play with friends'
                />
                <meta
                    name='google-site-verification'
                    content='B5v-PQVSYY7HLtaRNMMfRoWsng2DrwZ0T25xCqHCD5M'
                />
                <meta property='og:title' content='Skissan' />
                <meta
                    property='og:image'
                    content='https://skissan.com/og.png'
                />
                <meta
                    property='og:description'
                    content='Play online drawing games with your friends!'
                />
                <meta name='twitter:card' content='summary_large_image' />
            </Helmet>
            <Layout location={props.location}>{element}</Layout>
        </Provider>
    );
};

export default wrapper;
