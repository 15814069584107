exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-auth-discord-tsx": () => import("./../../../src/pages/auth/discord.tsx" /* webpackChunkName: "component---src-pages-auth-discord-tsx" */),
  "component---src-pages-cookie-policy-index-tsx": () => import("./../../../src/pages/cookie-policy/index.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-index-tsx" */),
  "component---src-pages-create-index-js": () => import("./../../../src/pages/create/index.js" /* webpackChunkName: "component---src-pages-create-index-js" */),
  "component---src-pages-games-index-tsx": () => import("./../../../src/pages/games/index.tsx" /* webpackChunkName: "component---src-pages-games-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-information-index-tsx": () => import("./../../../src/pages/information/index.tsx" /* webpackChunkName: "component---src-pages-information-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-room-index-tsx": () => import("./../../../src/pages/room/index.tsx" /* webpackChunkName: "component---src-pages-room-index-tsx" */),
  "component---src-pages-room-tsx": () => import("./../../../src/pages/room/[...].tsx" /* webpackChunkName: "component---src-pages-room-tsx" */),
  "component---src-pages-terms-of-service-index-tsx": () => import("./../../../src/pages/terms-of-service/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-index-tsx" */)
}

