import { configureStore } from '@reduxjs/toolkit';
import consentReducer from './reducers/consentSlice';
import drawingReducer from './reducers/drawingSlice';
import userReducer, { AuthUserState, fetchUserByToken } from './reducers/userSlice';

const isBrowser = typeof window !== 'undefined';

export const store = configureStore({
    reducer: {
        user: userReducer,
        consent: consentReducer,
        drawing: drawingReducer,
    },
});

if (isBrowser) {
    const userAuthStored = localStorage.getItem('userAuth');

    if (userAuthStored) {
        const userAuth: AuthUserState = JSON.parse(
            userAuthStored
        ) as AuthUserState;

        if (userAuth != null && userAuth.expiresAt > new Date().getTime() - 30 * 1000) {
            // store.dispatch(setUser(userAuth));
            store.dispatch(fetchUserByToken(userAuth.accessToken));
        }
    }
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
