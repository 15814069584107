import React from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../store'

export const useAdsenseLoader = () => {
    
    React.useEffect(() => {
        let adsenseScript:any = document.getElementById('adsense')

        if (adsenseScript == null) {
            // console.log('Created adsense script')
            adsenseScript = document.createElement('script')
            adsenseScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2076439748765757'
            adsenseScript.async = true
            adsenseScript.id = 'adsense'
            adsenseScript.crossorigin = 'anonymous'

            document.head.appendChild(adsenseScript)
        }
    }, [])
    return null
}

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector