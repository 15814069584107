export default {
    userApiProd: `https://skissan-prod.skissan.workers.dev`,
    userApiLocal: `https://skissan-dev.skissan.workers.dev`,
    wssProd: `wss://d1w2o9j5w7rs9u.cloudfront.net`,//`wss://skissan-prod.skissan.workers.dev`,
    wssLocal: `http://localhost:3000`,
    // userApi: `https://skissan-prod.skissan.workers.dev`,
    gameApiLocal: `http://localhost:3000`,
    gameApiProd: `https://d1w2o9j5w7rs9u.cloudfront.net`
    // hostname: `https://skissan-prod.skissan.workers.dev`,
    // wss: `wss://skissan-prod.skissan.workers.dev`,
    // hostname: `http://127.0.0.1:8787`,
};
