import React from 'react';
import * as styles from './index.module.scss';

type Props = {
    isVisible: boolean;
    children: any;
    title: string;
    actions: any;
};

const Modal = ({ isVisible, children, title, actions }: Props) => {
    if (!isVisible) {
        return null;
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <div className={styles.header}>
                    <h2>{title}</h2>
                </div>
                <div className={styles.content}>{children}</div>
                <div className={styles.actions}>{actions}</div>
            </div>
        </div>
    );
};

export default Modal;
