import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';
import { getRandomAvatar } from '../../helpers/avatarHelper';

const isBrowser = typeof window !== 'undefined';

export interface AuthUserState {
    isAdmin: boolean;
    created: number;
    accessToken: string | null;
    authType: 'discord';
    avatar: number[];
    isSignedIn: boolean;
    username: string;
    expiresAt: number;
    sessionId: string;
    name: string;
    totalScore: number;
}

const initialState: AuthUserState = {
    isAdmin: false,
    created: 0,
    accessToken: null,
    expiresAt: 0,
    authType: 'discord',
    avatar: [],
    isSignedIn: false,
    username: '',
    name: '',
    sessionId: 'new',
    totalScore: 0,
};

export const fetchUserByCode = createAsyncThunk<AuthUserState, string>(
    'users/fetchByCode',
    async (code, thunkAPI) => {
        // try {
        const isLocal =
            typeof window !== 'undefined' &&
            window.location.hostname === 'localhost';
        const res = await axios.get(
            `${
                isLocal ? config.userApiLocal : config.userApiProd
            }/auth/discord?code=${code}`
        );

        if (res.data && res.data.userData && res.data.oauthData) {
            return {
                ...res.data.userData,
                accessToken: res.data.oauthData.access_token,
                expiresAt:
                    new Date().getTime() + res.data.oauthData.expires_in * 1000,
                isSignedIn: true,
                avatar:
                    res.data.userData.avatar.length > 0
                        ? res.data.userData.avatar
                        : getRandomAvatar(),
            };
        }
        // } catch (err) {}
        return null;
    }
);

export const fetchUserByToken = createAsyncThunk<AuthUserState, string>(
    'users/fetchByToken',
    async (accessToken, thunkAPI) => {
        // try {
        const isLocal = window.location.hostname === 'localhost';
        const res = await axios.get(
            `${isLocal ? config.userApiLocal : config.userApiProd}/api/me`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        if (res.data && res.data.userData) {
            return {
                ...res.data.userData,
                isSignedIn: true,
                avatar:
                    res.data.userData.avatar.length > 0
                        ? res.data.userData.avatar
                        : getRandomAvatar(),
            };
        }
        // } catch (err) {}
        return initialState;
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState: () => {
        if (isBrowser) {
            const userAuthStored = localStorage.getItem('userAuth');

            if (userAuthStored) {
                const userAuth: AuthUserState = JSON.parse(
                    userAuthStored
                ) as AuthUserState;

                return userAuth;
            }
        }
        return { ...initialState, avatar: getRandomAvatar() };
    },
    reducers: {
        setUser: (state, action: PayloadAction<AuthUserState>) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        setName: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                name: action.payload,
            };
        },
        setUserAvatar: (state, action: PayloadAction<number[]>) => {
            localStorage.setItem(
                'userAuth',
                JSON.stringify({
                    ...state,
                    avatar: action.payload,
                })
            );
            return {
                ...state,
                avatar: action.payload,
            };
        },
        signOut: (state) => {
            return {
                ...initialState,
                name: state.name,
                avatar: state.avatar,
            };
        },
        setSessionId: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                sessionId: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchUserByCode.fulfilled, (state, action) => {
            // Add user to the state array
            const updatedState = {
                ...state,
                ...action.payload,
                name:
                    state.name === ''
                        ? action.payload.username || ''
                        : state.name,
            };

            localStorage.setItem('userAuth', JSON.stringify(updatedState));
            return updatedState;
        });
        builder.addCase(fetchUserByToken.fulfilled, (state, action) => {
            // Add user to the state array
            const updatedState = {
                ...state,
                ...action.payload,
                name:
                    state.name === ''
                        ? action.payload.username || ''
                        : state.name,
            };

            localStorage.setItem('userAuth', JSON.stringify(updatedState));
            return updatedState;
        });
    },
});

// Action creators are generated for each case reducer function
export const { setUser, signOut, setSessionId, setName, setUserAvatar } =
    userSlice.actions;

export default userSlice.reducer;
